export class User {

   /* ID               int        `gorm:"primary_key" json:"id"`
	Username         string     `gorm:"not null;unique" json:"username"`
	FirstName        string     `json:"firstName"`
	LastName         string     `json:"lastName"`
	Admin            bool       `json:"admin"`
	Cell             string     `json:"cell"`
	Email            string     `json:"email"`
	ProfileImageLink string     `json:"profile_link"`
	Active           bool       `json:"active" sql:"DEFAULT:true"`
	Password         string     `json:"password"`
	Business         []Business `gorm:"many2many:business_profile" json:"businesses"`*/
    public name: string;
    public email: string;
    public mobile: number;
    public password: string;
    constructor(){

    }
   overloadedconstructor(inname: string,inemail: string,inmobile: number,inpassword: string){
    this.name = inname;
    this.email = inemail;
    this.mobile = inmobile;
    this.password = inpassword;
   }
set_name(x: string){
    return this.name = x;
}
set_email(x: string){
    return this.email = x;
}
set_mobile(x: number){
    return this.mobile = x;
}
set_password(x: string){
    return this.password = x;
}

}
