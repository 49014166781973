import { Component, OnDestroy, OnInit } from '@angular/core';
import {
    trigger,
    style,
    animate,
    transition,
    // ...
} from '@angular/animations';



@Component({
    selector   : 'app',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss'],
    animations: [
        trigger(
            'enterAnimation', [
                transition(':enter', [
                    style({transform: 'translateX(100%)', opacity: 0}),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(-0%)', opacity: 1}))
                ]),
                transition(':leave', [
                    style({transform: 'translateX(0)', opacity: 1}),
                    animate('0.5s ease-in-out', style({ transform: 'translateX(100%)', opacity: 0}))
                ])
            ]
        )
    ],
})



export class AppComponent implements OnInit, OnDestroy
{
    public visible = 'content';
    fuseConfig: any;
    navigation: any;
    
    constructor()
    {
    }


    ngOnInit(){
        if (window.screen.width < 400) { // 768px portrait
            this.visible = 'none';
          }
    }

    ngOnDestroy(): void
    {

    }

    selectPage() {
        if (window.screen.width < 400) { // 768px portrait
            this.visible = 'none';
        }
        
    }

    openMenu(){
        if (window.screen.width < 400) {
            if (this.visible == 'none') {   
            this.visible = 'contents';
        } else {
            this.visible = 'none';
        }
    }
    }
    
}
