import {Component, Inject, OnInit, OnDestroy, ViewChild, ElementRef,ChangeDetectorRef} from '@angular/core';
import {interval} from 'rxjs/observable/interval';
import {map, takeUntil, startWith} from 'rxjs/operators';
import {Subject} from 'rxjs';
import {navigation} from '../navigation/navigation';
import {locale as navigationEnglish} from '../navigation/i18n/en';
import {locale as navigationTurkish} from '../navigation/i18n/tr';
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {FuseNavigationService} from '../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import {IImage} from '../modules/slideshow/IImage';
import * as $ from 'jquery';
import {MatTableDataSource} from '@angular/material/table';
import { Observable } from 'rxjs';
import { MatPaginator } from "@angular/material";
import {FormControl} from '@angular/forms';

import {
    trigger,
    state,
    style,
    animate,
    transition,
    // ...
} from '@angular/animations';



@Component({
  selector: 'app-events',
  templateUrl: './events.component.html',
  styleUrls: ['./events.component.scss']
})

export class EventsComponent implements OnInit {
  visible = 'content';
  myControl = new FormControl();
  options: string[] = ['Test', 'Engine'];
  filteredOptions: Observable<string[]>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  obs: Observable<any>;
  lubricantsNews = [{
    "title": "Tyre Fix",
    "description" : "This product is designed as an emergency repair to enable you to get to a repair shop. ",
    "date": "23 JUN 2021",
    "image_url":"../../assets/images/event/TyreFix.jpeg",
    "website":"http://novaslubricants.co.za/",
    "location":"",
    "contact":"tel:011-614-0505",
    "facebook":""
  },{
    "title": "Rat Repellent Empowerment Program",
    "description" : "Novas has a fantastic Rat Repellent and we are helping empower those less fortunate. Contact us if you wish to sell the product.",
    "date": "16 APR 2021",
    "image_url":"../../assets/images/event/Repellent.jpeg",
    "website":"http://novaslubricants.co.za/",
    "location":"",
    "contact":"tel:011-614-0505",
    "facebook":""
  },{
    "title": "Sanitizers Available",
    "description" : "Novas now producing sanitizer to help keep you save from the Corona virus.",
    "date": "20 APR 2020",
    "image_url":"../../assets/images/event/santizer.jpeg",
    "website":"http://novaslubricants.co.za/",
    "location":"",
    "contact":"tel:011-614-0505",
    "facebook":""
  },{
    "title": "We now do demos",
    "description" : "Let our team come to your store and show what our products can do. Feel free to just give us a call",
    "date": "20 DEC 2019",
    "image_url":"../../assets/images/event/novasDemo.jpeg",
    "website":"http://novaslubricants.co.za/",
    "location":"",
    "contact":"tel:011-614-0505",
    "facebook":""
  },
  {
    "title": "Novas' 3year Celebration",
    "description" : "We are celebrating our 3rd birthday. May we be there for you for many more years to come",
    "date": "4 APR 2018",
    "image_url":"../../assets/images/event/2018Bday.jpeg",
    "website":"http://novaslubricants.co.za/",
    "location":"",
    "contact":"tel:011-614-0505",
    "facebook":""
  },
  {
    "title": "Extend Launch at 1000 Bike show",
    "description" : "This is an amazing special on our new brand Xtend. We will be having a price give away as part of the new launch. We are looking forward to seeing you at the bike show. And hope you will join us for a day of fun. We want to congratulate Ms Brand on winning the event",
    "date": "30 JUN 2018",
    "image_url":"../../assets/images/event/bike.jpeg",
    "website":"https://savva.org.za/event/1000-bike-show/",
    "location":"https://www.google.com/maps?q=germiston&um=1&ie=UTF-8&sa=X&ved=2ahUKEwjavOr4yvfvAhVrShUIHWrfDwwQ_AUoAXoECAEQAw",
    "contact":"",
    "facebook":""
  }]
  dataSource = new MatTableDataSource(this.lubricantsNews); 
  constructor(
    @Inject(DOCUMENT) private document: any,
              private _fuseConfigService: FuseConfigService,
              private _fuseNavigationService: FuseNavigationService,
              private _fuseSidebarService: FuseSidebarService,
              private _fuseSplashScreenService: FuseSplashScreenService,
              private _fuseTranslationLoaderService: FuseTranslationLoaderService,
              private _translateService: TranslateService,
              private _platform: Platform,
              private changeDetectorRef: ChangeDetectorRef
              ) {

  }

  ngOnInit(): void {
    if (window.screen.width < 400) { 
      this.visible = 'none';
    }

    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value))
    );
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.obs = this.dataSource.connect();
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }
  
ngOnDestroy() {
    if (this.dataSource) {
      this.dataSource.disconnect();
    }
  }
}
