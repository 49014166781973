import {Component, OnInit, Inject} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs';
import * as $ from 'jquery';
import { AppService } from 'app/app.service';
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {FuseNavigationService} from '../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';


@Component({
    selector: 'app-contact',
    templateUrl: './contact-us.component.html',
    styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {
    submit_message: string;
    showNavigationArrows = false;
    showNavigationIndicators = false;
     private items = []
     lat_lub: number = -26.207556;
     lng_lub: number = 28.061804;

     lng_industrial: number = 28.0646918;
     lat_industrial: number = -26.2077029;

    form: FormGroup;
    formErrors: any;
    private _unsubscribeAll: Subject<any>;
    constructor(@Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private _formBuilder: FormBuilder,
    private novasService : AppService)
    {
        this.formErrors = {
            name: {},
            number:{},
            email: {},
            topic: {},
            message: {},
        };
        this._unsubscribeAll = new Subject();
        this.items = []
    };

    ngOnInit() {
        
        this.form = this._formBuilder.group({
            name: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            number: [''],
            topic: ['', Validators.required],
            message: ['', Validators.required],
        });
        this.form.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.onFormValuesChanged();
            });
            // this.novasService.getData("contact_us").subscribe(data => {
            //     this.items = data["data"], 
            //     console.log(data["data"]);
            //   })
    }

    onFormValuesChanged(): void {
        for (const field in this.formErrors) {
            if (!this.formErrors.hasOwnProperty(field)) {
                continue;
            }
            // Clear previous errors
            this.formErrors[field] = {};
            // Get the control
            const control = this.form.get(field);
            if (control && control.dirty && !control.valid) {
                this.formErrors[field] = control.errors;
            }
        }
    }

    onSubmit() {
        if (this.form.invalid) {
            return;
        }else{
            console.log(this.form["value"])
            this.novasService.postData("contact", this.form["value"]).subscribe(data => {
                this.submit_message = "Your question has been sent through to our team. Be on a lookout for a response"
                this.form.clearValidators()
                this.form.reset()
              })
        }
    }

    openMenu(){
        $('body').removeClass('noScroll');
        if ($('.collapse').hasClass('collapse-active')) {
            $('.collapse').removeClass('collapse-active');
        }
        else {
            $('.collapse').addClass('collapse-active');
        }
    }

}
