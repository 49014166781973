import { Component, OnInit , Inject} from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { AppService } from 'app/app.service';
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../../@fuse/services/config.service';
import {FuseNavigationService} from '../../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.scss']
})
export class AboutInfoComponent implements OnInit {
  about = {}; 
  constructor( @Inject(DOCUMENT) private document: any,
  private _fuseConfigService: FuseConfigService,
  private _fuseNavigationService: FuseNavigationService,
  private _fuseSidebarService: FuseSidebarService,
  private _fuseSplashScreenService: FuseSplashScreenService,
  private _fuseTranslationLoaderService: FuseTranslationLoaderService,
  private _translateService: TranslateService,
  private _platform: Platform,
  private route: ActivatedRoute, private novasService : AppService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params: ParamMap) => {
      let obj = params.get('title');
      this.about = this.novasService.getSingleAbout(obj)
    });
  }

}
