import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { User } from './model/user';
import { Observable, throwError } from 'rxjs';

interface novasData {
    data: Array<Object>
}

@Injectable()
export class AppService {
    constructor(private http: HttpClient,) { }

    currentUser:User;
    user = null;
    private configUrl = 'http://localhost:8080/api/v1/';
    
    
    // TMP
    getProducts(){
        return lubricantsProducts
    }
    
    getSingleProduct(name){
        let objSearched =  lubricantsProducts.find(item => {
            if (item.name == name) {
                return true;
        }})
        if (objSearched == undefined){
            var images404 = [["waiting.jpeg", "Grab a seat while we wait for them to fix the issue."]
            ,["cupcake.jpeg","We just having a cupcake while we waiting if you want to join."]
            ,["letsee.jpeg","The page seems to not be here, but if this is a valid request it should be here soon"]
            ,["nothere.jpeg","We tried checking but this page could not be found."]
            ,["thinking.jpeg","Mmm not sure if this is the correct page."]
            ,["unsure.jpeg","O no we could not find what you are looking for."]];
            var randColor = images404[Math.floor(Math.random() * images404.length)];
            return {
                "name" : "This product could not be found",
                "description" : randColor[1],
                "picture_url":"../../assets/images/404/" + randColor[0],
                "sizes" : [],
                "tags" : [],
                "specsheet":""
            }
        }
        return objSearched
    }

    // getProductTags(){
    //     let tagsFound = []
    //     lubricantsProducts.forEach(item => {
    //             item.tags.forEach(innerItem => {
    //                 if (innerItem != "") {
    //                     tagsFound.indexOf(innerItem) === -1 ? tagsFound.push(innerItem) : console.log("This item already exists");
    //                 }
    //             }) 
                
    //     })
    //     return tagsFound
    // }

    getAbouts(){
        return novasAbout
    }

    getSingleAbout(title){

        let objSearched =  novasAbout.find(item => {
            if (item.title == title) {
                return true;
        }})
        if (objSearched == null) {
            var images404 = ["download.png","f31b5bcda076125bf7010c781a4578a0.gif"];
            var randColor = images404[Math.floor(Math.random() * images404.length)];
            return {
                "title" : "This About page was not found",
                "summary": "This page seems to be missing",
                "descrption" : "This page could not be found. It has either been moved or removed.",
                "image_path":"../../assets/images/about/novasDemo.jpeg",
                "inner_image_path" : "../../assets/images/404/" + randColor,
              }
        }
        return objSearched
    }




    // Future code
    getData(endpoint) {
    return this.http.get<novasData>(this.configUrl + endpoint)
    }

    postData(endpoint : string, obj : Object){
        var newString = JSON.stringify(obj)
        var newJson = JSON.parse(newString)
        delete newJson["id"];
        return this.http.post("https://221ldpyyfe.execute-api.af-south-1.amazonaws.com/Dev/contact", newJson, {headers: new HttpHeaders({'Access-Control-Allow-Origin': '*','Content-Type':'*/*'})})
    }

    putData(endpoint : string, obj : Object){
        var newString = JSON.stringify(obj)
        var newJson = JSON.parse(newString)
        return this.http.put(this.configUrl + endpoint, newJson, {headers: new HttpHeaders({'Content-Type':'application/json'})})
    }

    deleteData(endpoint: string){
        return this.http.delete(this.configUrl + endpoint, {headers: new HttpHeaders({'Content-Type':'application/json'})})
    }

    login(username: string, password : string){
        let json = null;
        let data = this.http.post(this.configUrl + "login", "", {headers: new HttpHeaders({'Content-Type':'application/json', 'Authorization': 'Basic ' + btoa(username + ':' + password)})});
        let profile = data.subscribe(datas=> 
            
            {
                let jsondata = (datas['profile']);
                json=jsondata;
                localStorage.setItem('username',json['username']);
                /*json.subscribe(res=>
                    {
                        let name =res['id'];
                        console.log(name);
                        
                }) 
                console.log(jsondata);
                localStorage.setItem('token',JSON.stringify(datas['code']));
               //Object.entries(jsondata['profile']).map( res => {
               // console.log(res);   
               // });
                //let pro = JSON.stringify(datas['profile']);
                //let pro = datas['profile'].map(res=>
                //      {
                //            console.log(JSON.stringify(res['username']));
                //        })
                //var id = pro['username'];
                
                //this.user=JSON.stringify(data['token']);
                //console.log(JSON.stringify(data['username']));*/
            })
        //profile  
           
        // /console.log(profile);
           
        return data;

    }
}

// All About info
let novasAbout = [{
    "title" : "Who we are",
    "summary": "Our Mission Statment",
    "description" : "We are a team of hard working people striving to be the number one supplier of quality products in South Africa. Leading the charge is Marius Jansen van Vuuren and Kenneth Cockcroft with more than 40 years experience between the two of them.",
    "image_path":"../../assets/images/about/novasDemo.jpeg",
    "inner_image_path" : "../../assets/images/about/team.jpeg"
  },
  {
    "title" : "Novas Lubricants",
    "summary": "The retail facing side of our comapany",
    "description" : "We sell our wide range of automotive lubricants and addictives with a growing range of car care products.",
    "image_path":"../../assets/images/about/lfan.jpeg",
    "inner_image_path" : "../../assets/images/about/lproduct.jpeg"
  },{
    "title" : "Novas Industrial",
    "summary": "Need a better explanation",
    "description" : "Novas Industrial blends all lubricants from group 1 and group 2 base oils.<br><br> All products conform to the necessary industry specifications<br><br>Transformer oil regeneration service. We regenerate transformer oil to meet all SANS 555 (BS148) specifications.    All testing is done by an independent lab that is SABS accredited.    We also offer bulk storage or banking of old oil to be drawn by the client as required.",
    "image_path":"../../assets/images/about/industrial.jpeg",
    "inner_image_path" : "../../assets/images/about/regen.jpeg"
  },]

// All product Info
let lubricantsProducts = [{
    "name" : "5W30",
    "description" : "ENGINE OIL HIGH PERFORMANCE FULL SYNTHETIC<br> *5W30 API SN/CF <br> For petrol & diesel turbo vehicles Novas Lubricants 5w30 fully synthetic is specifically formulated for multi-valve high speed engines equipped with turbocharged & direct injection fuel systems. <br><br> *excellent engine protection <br> *API SN/CF<br> *Helps to improve Fuel efficiency <br><br> Meets & Exceeds all major manufacturing specs: ACEA C3, BMW LL-04, Ford 917A/M2C-917A,Porche A40/C30, MB 229.51/299.52/299.31, MB226.5, Dexos 2, Renault RN0710/RN0700, VW 504.00/507.00, VW 502.00/505.01",
    "picture_url":"../../assets/images/product/5W30.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "5W40",
    "description" : "For petrol & diesel turbo vehicles<br> Novas Lubricants 5w40 fully synthetic is specifically formulated for multi-valve high speed engines, equipped with turbocharged & direct injection fuel systems.<br> *Excellent engine protection<br> * API SN/CF<br> *Provides improved oxidation, resistance, deposit protection, better wear.<br> <br> Meets & Exceeds all major manufacturing specs:<br> ACEA A3/B3/B4-10, Opel LL-025, BMW LL-01,Porsche A40, MB 229.3/229.5,Renault RN710/700, VW 502.00/505.00",
    "picture_url":"../../assets/images/product/5W40.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "10W30",
    "description" : "For petrol & diesel turbo vehicles<br> <br> Novas Lubricants 10W30 fully synthetic is a Modern, year-round, high performance low-friction engine oil which has been formulated for diesel engines with & without exhaust-gas turbo charging. <br><br> *Outstanding wear protection properties, and maximizes length of time between oil changes <br><br> Meets & Exceeds all major manufacturing specs: ACEA A3/B3/B4-08, MB 229.3, VW 502.00/505.00, BMW LL-01,GM-LL-B-025, PORSCHE A40.",
    "picture_url":"../../assets/images/product/10W30.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [""],
    "specsheet":""
},{
    "name" : "10W40",
    "description" : "For petrol & diesel turbo vehicles<br> Novas Lubricants 10W40 fully synthetic is a Modern, year-round, high performance low-friction engine oil which has been formulated for diesel engines with & without exhaust-gas turbo charging.<br> <br> *Outstanding wear protection properties, and maximizes length of time between oil changes.<br> <br> Meets & Exceeds all major manufacturing specs: ACEA A3/B3/B4-08, MB 229.3, VW 502.00/505.00, BMW LL-01, GM-LL-B-025,PORSCHE A40.",
    "picture_url":"../../assets/images/product/10W40.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "15W40",
    "description" : "High performance, heavy duty diesel engine oil formulated for use in a wide variety of diesel and petrol engines operating under severe service conditions. Designed to provide improved engine protection and increased oil life. Compatible with low-emission engine technology used in road transport, construction, mining and many other industries. Specifications: 15W40 (API SL/CH4) High performance Turbo petrol and Diesel oil has been formulated to meet and exceed the following specifications. ACEA E7-04. CUMMINS CES 20076, 200",
    "picture_url":"../../assets/images/product/15W40N.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1s",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "15W40 High performance",
    "description" : "High performance, heavy duty diesel engine oil formulated for use in a wide variety of diesel and petrol engines operating under severe service conditions. Designed to provide improved engine protection and increased oil life. Compatible with low-emission engine technology used in road transport, construction, mining and many other industries. Specifications: 15W40 (API SL/CH4) High performance Turbo petrol and Diesel oil has been formulated to meet and exceed the following specifications. ACEA E7-04. CUMMINS CES 20076, 200",
    "picture_url":"../../assets/images/product/15W40.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "20W50 High Performance", 
    "picture_url":"../../assets/images/product/20W50.png",
    "description" : "Suitable for all petrol and naturally aspirated diesel engines of passenger cars, light commercial vehicles and farm equipment for which multi-grade viscosity grade oils are specified. Specifications: Meets or exceeds the following specifications. API SF/CC, MIL-L-46152, CRC L-38 Bearing Test, SABS 1361.",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},
{
    "name" : "20W50 Econ",
    "picture_url":"../../assets/images/product/20W50HP.png",
    "description" : "High mileage multigrade engine oil formulated for older engines as well as vehicles covering longer distances. Suitable for all petrol passenger and light commercial vehicles",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "75W140",
    "description" : "APPLICATION: Manual transmission transaxies, planetary hub & other spur gear axles which specifically require EP gear oils meeting API GL-5<br> <br> BENEFITS : High performance, EP additives system controls transmission gear tooth wear, excellent load carrying capacity to protect gear equipment against surface stress under heavily loaded conditions. GL-5 gear oils have been fortified with anti-wear, anti-oxidant, anti-foam, anti-corrision and extreme pressure additives. <br> PERFORMANCE LEVELS:<br> Meets or Exceeds:<br> *MIL-2105-D(ABSOLUTE). MAN 342M-2 REAR AXLES DRAIN INTERVAL 160 000KM. MAC GO-G. ZF TE ML 05A, 07A, 12E,16B, C, D, 17B, & 19B",
    "picture_url":"../../assets/images/product/75W140.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "80W90",
    "description" : "APPLICATION: Manual transmission transaxles, planetary hub & other spur gear axles which specifically require mild EP gear oils meeting API GL-5 BENEFITS: High performance, EP additives system controls transmission gear tooth wear, excellent load carrying capacity to protect gear equipment against surface stress under heavily loaded conditions. (GL-5 gear oils have been fortified with anti-wear, anti-oxidant, anti-foam, anti-corrosion and extreme pressure additives. <br> <br> PERFORMANCE LEVELS:<br> MEETS OR EXCEEDS:<br> *MIL - 2105 - D (ABSOLUTE). MAN 342M-2 FOR AXLES. MAC GO-G. ZF TE ML 05A,07A, 12E, 16B, C, D, 17B & 19B",
    "picture_url":"../../assets/images/product/80W90.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Anti-Freeze Green",
    "picture_url":"../../assets/images/product/Anti-Freeze Green.png",
    "description" : "Novas Anti-freeze is specifically formulated for South Africa's variable conditions and new generation aluminium radiators. Benefits: Novas Anti-Freeze has the following benefits to offer your radiator. It protects the radiator from the following: Hardening and cracking of seals, Corrosion of aluminium and metals, Acts as an Anti-Freeze, Over-heating, Scale build-up and Sludge build-up.",
    "sizes" : [{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 210,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Anti-Freeze Blue",  
    "picture_url":"../../assets/images/product/Anti-Freeze Blue.png",
    "description" : "Novas Anti-freeze is specifically formulated for South Africa's variable conditions and new generation aluminium radiators. Benefits: Novas Anti-Freeze has the following benefits to offer your radiator. It protects the radiator from the following: Hardening and cracking of seals, Corrosion of aluminium and metals, Acts as an Anti-Freeze, Over-heating, Scale build-up and Sludge build-up.",
    "sizes" : [{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 210,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Anti-Freeze Red",
    "picture_url":"../../assets/images/product/Anti-Freeze Red.png",
    "description" : "Novas Anti-freeze is specifically formulated for South Africa's variable conditions and new generation aluminium radiators. Benefits: Novas Anti-Freeze has the following benefits to offer your radiator. It protects the radiator from the following: Hardening and cracking of seals, Corrosion of aluminium and metals, Acts as an Anti-Freeze, Over-heating, Scale build-up and Sludge build-up.",
    "sizes" : [{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 210,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "ATF-DXII",
    "description" : "Suitable for transmissions as listed below as well as power steering. Specifications: Automatic transmission fluid formulated to meet and exceed the following specifications. GM6137M - Dexron®II, Daimler-Benz 236,6, Caterpillar Toz, Allissan C3 2F, Power steering pump, Clark transmissions, Vikers D2882.",
    "picture_url":"../../assets/images/product/ATF-DXII.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 210,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "ATF-DXIII",
    "description" : " Multi vehicle DX-III is an advanced technology Semi Synthetic, multi vehicle, automatic Transmission fluid that Exceeds the requirements of GM Dexron®️ IIIH. It is manufactured from the latest technology and advanced base oils along modifier to minimize shear loss and provide outstanding performance through the of the fluid. DX-III has a drain period up to 160.000 Km.<br> <br> APPLICATIONS : DX-III is designed for use in passenger cars, light and heavy duty truck automatic Transmissions, power steering units, rotary vane and screw type compressors, Hydraulic systems and Manual Transmissions where this type of fluid is specified. DX-III is recommended for use in all vehicles that require a GM Dexron®️ II or III type product such as all Opel, and Toyota, vehicles up to MY2006 as well as in 4 speed automatic Transmissions used by Ford & Ssang Yong. It can also be used in Mitsubishi automatic up to and including SP2 Specification, Clark Powershift Transmissions, Allison World (W) Series Automatic Transmissions.<br> DX-III is suitable for use where GM Dexron®️ IIIF & IIIG are recommended and can also be used wherever GM Dexron®️ II type fluids were recommended. It is not suitable for use where GM Dexron ®️ fluids are specified.",
    "picture_url":"../../assets/images/product/ATF-DXIII.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 210,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Battery Acid",
    "description" : "Suitable for all makes of wet acid batteries",
    "picture_url":"../../assets/images/product/Battery Acid.png",
    "sizes" : [{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },
    {
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Battery Water",
    "picture_url":"../../assets/images/product/Battery Water.png",
    "description" : "Suitable for all makes of wet acid batteries",
    "sizes" : [{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },
    {
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Brake Fluid Dot4",
    "picture_url":"../../assets/images/product/Brake Fluid Dot4.png",
    "description" : "Suitable for all hydraulic brake and clutch systems including disc brakes. Specifications: Exceeds SA Government compulsory speciifications and complies with SAEJ 173 and DOT4All the text",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 200,
    },{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    }] ,
    "tags" : [],
    "specsheet":""
},{
    "name" : "Brake, Part and Chain",
    "description" : "Cleans brakes & restores system performance <br><br> . Heavy duty cleaner for braking systems, components and chains without disassembly.<br> . Effectively removes brake dust, dirt, grease and gunge deposits.<br> . Reduces disc brake squeal.<br> . Fast drying with no residue.<br> <br> DIRECTIONS FOR USE: . Remove wheel to expose brake system.<br> . Spray liberally onto area to be cleaned.<br> Allow to air dry. <br><br> CAUTION: DANGER - Extremely flammable aerosol. Pressurized container: Protect from sunlight and do not expose to temperatures exceeding 50°C. Keep away from heat / sparks / flames / hot surfaces. No smoking. Do not spray onto painted surfaces. Do not pierce or burn, even when empty. 100 % af mass of the contents are flammable. Causes skin and eye irritation. May cause drowsiness or dizziness. Keep out of reach of children. <br><br> WARNING: . Avoid spraying onto painted surfaces . Wash off with water immediately.",
    "picture_url":"../../assets/images/product/BPC.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 210,
    }],
    "tags" : [],
    "specsheet":"",
},{
    "name" : "Carb Cleaner",
    "description" : "Removes Carbon Deposits INSTANTLY & EFFECTIVELY for maximum performance<br><br> DIRECTIONS : Put plastic extension tube firmly in valve button. Engine must be slightly warm to begin clean up.<br> Carburetor, automatic chokes and carburetor linkage:<br> Take out air cleaner and spray CARB CLEANER on whole exterior /interior surface of carburetor, as well as the linkage and choke (valve) shaft. Soak for 4 minutes and respray to take away any excess grease, dirt and deposits. To clean the automatic choke, spray both ends of the choke valve while opening and closing by hand - spray vacuum cylinder to remove gum and dirt. PVC Valves: Switch off engine, disconnect crankcase side of PVC valve. Put plastic tube of CARB CLEANER into open end of valve and spray while working valve back and forth. Switch engine on and spray again to flush valve completely. <br><br> WARNING: Do not get on paint work <br><br> DANGER: Extremely flammable, harmful or fatal if swallowed. Vapour harmful to eyes. <br><br> CAUTION: Do not store in areas above 50C, pressurized container. <br>Do not puncture or incinerate.<br> Keep out of reach of children",
    "picture_url":"../../assets/images/product/Carb Cleaner.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 200,
    },{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Chain Lube",
    "description" : "NON FLING LINKAGE & CHAIN PROTECTOR CHAIN LUBE<br> Advanced anti-friction metal treatment concentrate<br> <br> Xtend Chain Lube is a strong adhesive, chain penetrating lubricant for motorcycles, quad & other chain-operated applications. <br><br> *Xtends Chain & Sprocket life<br> *Water repellent<br> *Non conductive<br> * O-ring safe<br> <br> DIRECTIONS : Clean chain well with Novas Brake & Chain Cleaner. Spray directly onto Chain while rotating wheel to cover all links.<br> <br> APPLICATIONS: Best for extreme conditions<br> *Reduces friction & wear<br> *Operates at very high temp range<br> *Dirt, water & corrosion resistant<br> <br> WARNING: Do not get on paint work<br><br> DANGER: Extremely flammable, harmful or fatal if swallowed. Vapour harmful to eyes.<br><br> CAUTION: Do not store in areas above 50°C, pressurized container.<br><br> Do not puncture or incinerate.<br><br> Keep our of reach of children",
    "picture_url":"../../assets/images/product/Chain Lube.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Compressor Oil",
    "description" : "HIGH PERFORMANCE<br> <br> A blend of selected high viscosity index mineral oils and additives intended for the lubrication of reciprocating piston compressors, as well as certain rotary vane and screw compressors. <br><br> SPECIFICATIONS:<br> *Compressor 68<br> *DIN 51506 (VBL;VCL;VDL)<br> *ISO / DP 6521 (DAA; DAB; DAH; DAG)<br><br> APPLICATIONS:<br> Suitable for use in piston compressors, as well as oil flooded or oil injected Rotary vane compressors. <br> Suitable for use in single or two stage Rotary screw compressors with discharge temperatures up to 120°C.",
    "picture_url":"../../assets/images/product/Compressor Oil.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Diesel Injector Cleaner",
    "description" : "HIGH PERFORMANCE FOE DIESEL ENGINES<br> <br> APPLICATIONS :<br> *Cleans injectors, turbo EGR valve and diesel particulate filter. <br> * Lubricates injector increasing efficiency and extending injector life. <br> *Assists with water separation for complete removal of free water <br> *Prevents diesel freeze and filter clogging in cold temperature environment<br> <br> DIRECTIONS <br> *Add to diesel tank at least every 5000km's or as required <br> *Treats up to 70 litres of fuel<br>",
    "picture_url":"../../assets/images/product/Diesel.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},
// {
//     "name" : "Electrical Contact Cleaner",
//     "description" : "Industrial grade electrical contact & mechanical component cleaner <br><br> Quickly dissolves oil and grease and removes dust and grime from electrical contact and mechanical components. For industrial strength cleaning of electrical switches and controls, electric motors and coils. Rapid evaporation allows minimum downtime on electrical equipment Safe to use on Radio Control equipment and PC boards. <br><br> DANGER: Extremely flammable, harmful or fatal if swallowed. Vapour harmful to eyes.<br> CAUTION: Do not store in areas above 50°C, pressurized container. Do not puncture or l incinerate. <br><br> Keep out of reach of children",
//     "picture_url":"../../assets/images/product/",
//     "sizes" : [{
//         "units" : "30",
//         "measurement":"ml",
//         "scale": 500,
//     }],
//     "tags" : [],
//     "specsheet":"",
// }
{
    "name" : "Engine Cleaner",
    "picture_url":"../../assets/images/product/Engine Cleaner.png",
    "description" : "Novas Engine Cleaner is a strong cleaner and degreaser for all engines, cars, motorcycles, machinery and other types of dirt for example: greasy tools, equipment and floors.",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Engine Cleaner (Water based)",
    "picture_url":"../../assets/images/product/Engine Cleaner (Water based).png",
    "description" : "Novas Engine Cleaner is a strong cleaner and degreaser for all engines, cars, motorcycles, machinery and other types of dirt for example: greasy tools, equipment and floors.",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Engine Flush",
    "picture_url":"../../assets/images/product/Engine Flush.png",
    "description" : "Novas Engine Flush removes sludge, frees hydraulic lifters, rings and valves. It acts as a compression stabiliser.",
    "sizes" : [{
        "units" : "20",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Engine Treatment",
    "description" : "XTEND FOR MAXIMUM PROTECTION <br><br> Advanced Anti-friction metal treatment concentrate <br><br> *Extends engine life<br> *Increase performance<br> *Maximum protection at start-up<br> <br> DIRECTIONS - The contents of this bottle is enough to treat up to 5 litres of engine oil. Novas Engine Treatment 500ml is:(a) Sufficient to treat engines with a sump capacity of 5 Litre. (b) Compatible with all standard motor oils/synthetic motor oils and oil additive products. (c) Effective for both petrol and diesel fuel engines.<br> <br> This product does not contain : P.T.F.E resins (Teflon) molybdenum disulfide, zinc, graphite, or solids which can build up on engine parts, clog oil filters, impede the flow of oil or potentially harm the environment.<br><br> CAUTION: Contains petroleum distillates. After skin exposure wash with soap & water. After eye contact, flush eyes with water. If swallowed, DO NOT induce vomiting. Call physician immediately.<br><br> KEEP OUT OF REACH OF CHILDREN",
    "picture_url":"../../assets/images/product/EngineTreatment.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Engine Stop Leak",
    "description" : "HIGH PERFORMANCE<br> For petrol and diesel engines<br> <br> Add contents to engine, manual gearbox or differential. Treats up to 5 litres. Will help to reduce or stop oil leakage through shrunken rubber oil seals, gaskets, o-rings or valves stem oil seals",
    "picture_url":"../../assets/images/product/ESL.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "EP90",
    "picture_url":"../../assets/images/product/EP90.png",
    "description" : "For use in manual gearboxes and differentials where an extreme pressure gear oil is required. Specifications: Meets and exceeds the following specifications API GL4, MIL-L-2105, GM9985133, FORDS-MZC1011A",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Hydraulic Oil",
    "picture_url":"../../assets/images/product/Hydraulic Oil.png",
    "description" : "High quality anti-wear hydraulic fluid formulated for use in high pressure stationary and mobile hydraulic systems. It incorporates Zinc phosphates additive technology. Applications: Novas 68 hydraulic oil has the following applications. Wide range of industrial hydraulic equipment, Hydraulics on contruction and mobile equipment, Hydraulic systems with gear vane or piston pumps, Industrial circulating systems, Machine tools and enclosed gear systems, Plastic injection moulding. NOT RECOMMENDED FOR SYSTEMS WITH SILVER PLATED COMPONENTS.",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Injector Cleaner",
    "picture_url":"../../assets/images/product/Injector Cleaner.png",
    "description" : "Novas Injector Cleaner removes gums and varnishes from carburettors and injectors. Removes moisture from fuel systems. Removes carbon deposits and oxides formed in carburettors and injectors. Maintains PCV valve efficiency. Frees sticky valves and rings. Stabilisers compression. Lubricates upper cyclinder area.",
    "sizes" : [{
        "units" : "20",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Kleen Foam Degreaser",
    "description" : "All purpose cleaner<br> <br> DIRECTIONS FOR USE: Spray on dirty greasy parts. Leave for 5 minutes. <br> Agitate stubborn dirty with brush. <br> Hose off preferably with high pressure spray. If residue is left wipe with damp sponge or cloth and hose off again. <br> Good for cleaning oil & grease stains off paving or any other greasy surfaces.<br> <br> Do not spray on hot engines. Avoid unnecessary contact with skin.<br> <br> CAUTION : Can harm paint, wash off immediately with water<br> <br> Keep away from children",
    "picture_url":"../../assets/images/product/Kleen Foam Degreaser.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Lacquer Thinners", 
    "description" : "Dilutes lacquer based paints, cleans brushes, rollers & spray equipment. <br><br> FLAMMABLE / POISONOUS Handle with care <br> Flammable / poisonous<br> Handle with care<br> <br> 1. Dangerous if swallowed. Seek medical attention immediately.<br> *Do not induce vomiting *<br> 2. Do not smoke while using this product<br> 3. Store in cool place away from heat and sparks<br> 4. Keep out of reach of children<br>",
    "picture_url":"../../assets/images/product/Lacquer Thinners.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Methylated Spirits",
    "description" : "Used in primus stoves & household lighting.<br> Used for general cleaning. <br><br> FLAMMABLE /POISONOUS Handle with care <br> Flammable / poisonous<br> Handle with care<br> <br> 1. Dangerous if swallowed. Seek medical attention immediately.<br> *Do not induce vomiting *<br> 2. Do not smoke while using this product<br> 3. Store in cool place away from heat and sparks<br> 4. Keep out of reach of children<br>",
    "picture_url":"../../assets/images/product/Methylated Spirits.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Mineral Turpentine",
    "description" : "A slow drying solvent to thin alkyd enamels & varnishes.<br> Cleans brushes, rollers & spray equipment.<br> <br> Flammable / poisonous<br> Handle with care<br> <br> 1. Dangerous if swallowed. Seek medical attention immediately.<br> *Do not induce vomiting *<br> 2. Do not smoke while using this product<br> 3. Store in cool place away from heat and sparks<br> 4. Keep out of reach of children<br>",
    "picture_url":"../../assets/images/product/Mineral Turp.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Oil Treatment",
    "description" : "For petrol and diesel engines<br><br> *Reduces oil consumption <br> *Improves compression <br> *Maintains oil pressure at high temperatures<br> *Fully compatible with synthetic & mineral oil<br> *Quietens noisy engines, manual gearboxes and diffs<br><br> DIRECTIONS :<br> Add to engine oil at operating temperature. <br> Use up to 10% per volume of oil",
    "picture_url":"../../assets/images/product/Oil Treatment.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Radiator Flush",
    "description" : "Safe for all metals and plastics<br> <br> Removes rust, scale & sludge from cooling system.<br> <br> DIRECTIONS : <br>Add to radiator & open heater run engine for +/- 10 mins at fast idle or operating temperature. <br>Drain cooling system, then add clean water & novas anti-freeze & novas radiator stop leak if necessary.",
    "picture_url":"../../assets/images/product/Radiator Flush.png",
    "sizes" : [{
        "units" : "20",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Radiator Stop Leak",
    "description" : "HIGH PERFORMANCE<br> Prevents and stops leaks<br> <br> Seals leaks in radiators and engine blocks.<br> Compatible with all anti-freezes.<br> Protects all metals, plastics & hoses.<br> Prevents rust, scale & sludge build up.<br> <br> DIRECTIONS : Add contents to radiator with engine idling & heater open.<br>",
    "picture_url":"../../assets/images/product/RSL.jpeg",
    "sizes" : [{
        "units" : "20",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Rat Repellend",
    "description" : "Mixture of aromatic substances for the faz end easy removal of marten odor trails in and on the vehicle. Protects rubber and plastic parts, wiring, camping equipment, caravans etc...preventing expensive damage caused by rodents. Use biocides safely. Always read the label and product information before use <br> APPLICATION: Shake can vigorously before use.<br> *Spray rubber and plastic parts or area to be protected thoroughly<br> * For optimum protection repeat spraying every 14 days.<br> <br> WARNING: Extremely flammable aerosol. Pressurized container. Keep out of reach of children.<br> Keep away from heat, hot surfaces, sparks, open flames and other ignition sources.<br> Do not pierce or burn, even after use. Do not expose to temperature exceeding 50°C. Avoid contact with eyes and skin.<br>",
    "picture_url":"../../assets/images/product/Rat Repellend.png",
    "sizes" : [{
        "units" : "",
        "measurement":"ml",
        "scale": 200,
    },{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
}
,{
    "name" : "Refresh Germ Defogger",
    "description" : "Disinfectant spray<br> <br> * Disinfects<br> *Eliminates odours<br> * Kills Germs & Bacteria<br><br> NOVAS REFRESH is a high effective sanitiser spray for household and office use.<br> *Eliminates odours<br> *Kills Bacteria<br> *Anti-fungal<br> *400X MORE EFFECTIVE THAN CITRONELLA AS A TICK & FLEA REPELLENT IN ANIMAL SPACES EG: KINNELS, STABLES ETC...<br> <br> DIRECTIONS FOR USE: Spray liberally in desired area and leave to settle for 5 minutes. <br><br> CAUTION: DANGER - Extremely flammable aerosol. Pressurized container : Protect from sunlight & do not expose to temperatures exceeding 50°C. No smoking. Do not spray onto paint surfaces.<br><br> KEEP OUT OF REACH OF CHILDREN",
    "picture_url":"../../assets/images/product/Refresh Germ Defogger.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},
// Refresh Sanitizer
{
    "name" : "Refresh Sanitizer",
    "description" : "*Disinfects<br> *Eliminates odours<br> *Kills Germs & Bacteria <br><br> NOVAS REFRESH is a highly effective disinfectant spray for your car air- conditioning system. Due to the build up of mold and bacterial proliferations, continued used of your air-conditioning system under these unhealthy conditions could lead to allergic reactions such as sneezing, colds & unpleasant odours. <br><br> NOVAS REFRESH effectively sanitises your air-conditioning system, resulting in long lasting clean odourless air delivery, and a healthier driving environment. <br><br> USE AT 6 MONTHLY INTERVALS <br><br> CAUTION : DANGER - Extremely flammable aerosol. Pressurized container :Protect from sunlight & do not expose to temperatures exceeding 50°C. No smoking. Do not spray onto painted surfaces. KEEP OUT OF REACH OF CHILDREN <br><br> WARNING :<br> * Avoid spraying onto painted surfaces<br> * Wash off with water immediately.",
    "picture_url":"../../assets/images/product/Refresh Sanitizer.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},
{
    "name" : "SAE30",
    "description" : "ENGINE OIL HIGH PERFORMANCE<br> <br> PERFORMANCE LEVELS MEETS OR EXCEEDS:<br> . API SF/CC<br> . MIL-L-46152<br> . CRC L - 38 BEARING TEST<br> . SABS 1361<br> <br> APPLICATION: Suitable for all petrol & naturally aspirated diesel engines of passenger cars, light commercial vehicles & farm equipment for which mono-grade viscosity grade oils are specified. <br><br> . Provides excellent lubricantion<br> . Keeps engine clean<br> . Prolongs engine life<br> . Contains Superior Friction Reducing Additive<br>",
    "picture_url":"../../assets/images/product/SAE30.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":"",
},
{
    "name" : "SAE40",
    "description" : "ENGINE OIL HIGH PERFORMANCE<br> <br> PERFORMANCE LEVELS MEETS OR EXCEEDS:<br> . API SF/CC<br> . MIL-L-46152<br> . CRC L - 38 BEARING TEST<br> . SABS 1361<br> <br> APPLICATION: Suitable for all petrol & naturally aspirated diesel engines of passenger cars, light commercial vehicles & farm equipment for which mono-grade viscosity grade oils are specified. <br><br> . Provides excellent lubricantion<br> . Keeps engine clean<br> . Prolongs engine life<br> . Contains Superior Friction Reducing Additive<br>",
    "picture_url":"../../assets/images/product/SAE40.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":"",
}
,{
    "name" : "Screen Kleen",
    "description" : "WINDSCREEN WASHER FLUID 70ml<br>DIRECTIONS: Empty contents into windscreen washer bottle",
    "picture_url":"../../assets/images/product/Screen Kleen.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
}
,{
    "name" : "Supacool Antifreeze Premix 50",
    "description" : "Engine Coolant Premix for Petrol & Diesel engines Cools & Protects up to 4 years <br><br> DIRECTIONS: Mix at 50% with water for protection up to - 15°C <br><br> FEATURES AND BENEFITS<br> *Mixture of monoethylene Glycol and rust inhibitors to provide effective engine cooling without boiling<br> *Protection against corrosion of all alloys used in cooling systems of modern vehicles<br> *Protection against freezing depending on concentration<br> *Protection against electrolysis & cavitation corrosion<br> *Efficient lubrication of water pumps<br> <br> WARNINGS: If splashed in eyes or on skin - wash thoroughly with running water.<br> If swallowed, do not induce vomiting - get madical attention immediately.<br> <br> SPECIFICATIONS : Meets or Exceeds<br> * ASTM D3306 1<br> * BS 6580:2010<br>",
    "picture_url":"../../assets/images/product/Supacool Antifreeze Premix 50.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":""
},{
    "name" : "Supacool Antifreeze Concetrate 90",
    "description" : "Engine coolant concetrate for petrol & diesel engines cools & protects up to 4 years <br> <br> DIRECTIONS: Mix at 50% with water for protection up to - 36°C<br> <br> * Mixture of monoethylene Glycol and rust inhibitors to provide effective engine cooling without boiling. <br> *Protection against corrosion of all alloys used in cooling systems of modern vehicles <br> *Protection against freezing depending on concentration <br> * Protection against electrolysis & cavitation corrosion <br> *Efficient lubrication of water pumps<br> <br> WARNINGS: If splashed on eyes or on skin - wash thoroughly with running water. <br> If swallowed, do not induce vomiting - get medical attention immediately. <br> <br> SPECIFICATIONS : Meets or Exceeds <br> . ASTM D3306 1<br> . BS 6580:2010<br>",
    "picture_url":"../../assets/images/product/Supacool Antifreeze Concetrate 90.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":""
},
{
    "name" : "Sparkle Cockpit Spray",
    "description" : " Clean & beautify your car inside & out . Anti-Static Properties . Rejuvenate . Freshens & Protects . NOVAS Sparkle is a silicone based with a pleasant fragrance, drying agenda and mild detergent to clean and beautify your car inside and out. . Polishes and protects vinyl, rubber, plastic, leather, aluminium, brass and wood. OTHER APPLICATIONS/USES: Waterproof electrical equipment, keep ignition systems working in wet conditions, protect fishing and sports equipment, lubricate hinges, locks, windows, doors, sliding door, drawers. DIRECTIONS <br><br> . Shake can well<br> . Spray on to soft cloth for dash application, to avoid spraying on windscreen.<br> . Apply to surface. <br> . Buff with soft cloth.<br> <br> CAUTION : Contents under pressure and combustible<br> <br> Do not store in open sunlight-keep away from heat or flame. <br>Use in well ventilated area.<br> If swallowed do NOT induce vomiting call physician immediately. <br>If product comes in contact with eyes, flush thoroughly with water.<br> Keep out of reach of children. <br>Do not puncture, incinerate or store above 50°C.",
    "picture_url":"../../assets/images/product/CockpitSpray.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":"",
},
{
    "name" : "Transmission Fluid",
    "description" : "Ultra high-performance fluid especially designed for use in many CVT-gearboxes using push-belts or transmission chains made of steal<br> <br> DESCRIPTION<br> Specifically recommended for use in latest generation of continuously variable automatic transmissions (CVT)<br><br> APPLICATION:<br> Novas ATF CVT has been tested for use in many cv-transmissions including the latest generation of Mercedes Benz autotronic transmissions.<br> Novas ATF CVT is miscible and compatible with conventional branded gear oils however a complete oil change is recommended when converting to ATF CVT.<br><br> SPECIFICATIONS: Meets & Exceeds :<br> *BMW 83 22 0 136 376. BMW 83 22 0 439. NISSAN NS-1. NISSAN NS-2. NISSAN NS-3. SUZUKI CVT GREEN 1. SUZUKI CVTF TC. SUZUKI NS-2. TOYOTA CVTF TC. TOYOTA CVTF FE. VW TL 521 80 (G 052 180). MB 236.20. FORD WSS-M2C 928A. HYUNDAI SP-111. DAIHATSU AMMIX CVT. CHRYSLER JEEP NS-2. HONDA HMMF1. HONDA HCF2. MITSUBISHI SP-111. MITSUBISHI CVTF-J1. SUBARU ECVT. SUBARU ICVT. MINI COOPER EZL 799 <br><br> KEEP OUT OF REACH OF CHILDREN ",
    "picture_url":"../../assets/images/product/Transmission Fluid.png",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":"",
},
{
    "name" : "Throttle body & Air intake cleaner",
    "description" : "Cleans EGR, Turbo & Fouled Components in PETROL & DIESEL ENGINES<br> <br> DIRECTIONS : 1. Removes air filter and spray into air intake<br> 2. Wait a few minutes then start the engine.<br> 3. Spray short bursts into the throat while idling.<br> 4. Rev moderately to draw the cleaner through the throttle body.<br> 5. Do not allow revs to increase over 1500rpm on diesel engines.<br> <br><br> WARNING: Do not get on paint work.<br> DANGER: Extremely flammable, harmful or fatal if swallowed. Vapour harmful to eyes.<br> CAUTION: Do not store in areas above 50°C pressurized container.<br> Do not puncture or incinerate.<br> <br> KEEP OUT of reach of children",
    "picture_url":"../../assets/images/product/ThrottleIntakeCleaner.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":"",
},{
    "name" : "Transformer Oil",
    "description" : "HIGH PERFORMANCE<br> <br> APPLICATIONS :<br> This oil can be used for oil filled welding machines, electro magnets and transformers<br> <br> . Exceptional resistance to ageing & degradation.<br> . Modeling predicts good to superior cooling in service.<br> . Essentially zero Sulphur minimizes risks due to corrosive Sulphur.<br> . Safety benefits due to higher flash point and lower volatility.<br> . Easy to use with other oil, no miscribility / compatibility or solvency issues found.<br> <br> SPECIFICATIONS : <br> This oil meets and exceeds SANS 555 (BS148) SPECIFICATIONS<br>",
    "picture_url":"../../assets/images/product/TransformerOil.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    }],
    "tags" : [],
    "specsheet":"",
},
{
        "name" : "Transmission & Power Steering Treatment",
        "description" : "Contains advanced Anti-friction metal treatment<br> <br> . Protects Metal Surfaces<br> . Maximizes Equipment Life<br> . Reduces Friction and Wear.<br> <br> DIRECTIONS - The contents of this bottle treats 2,5litres Transmission & Power Steering Tratment<br> <br> NOTE: Do not use in manual Gearbox and do not use with fully synthetic A.T.F. (eg: DEXRON VI)<br> <br> APPLICATIONS : Car and truck automatic transmissions.<br> On-Highway heavy-duty automatic transmissions.<br> Off-Highway heavy duty automatic transmissions.<br> Trans-axles and power steering systems.<br> <br> PRODUCT INFORMATION : Novas Xtend Transmission & Power Steering Tratment is a scientifically blended formula designed to be blended with all non synthetic transmissions fluids <br><br> Keep out of reach of children",
        "picture_url":"../../assets/images/product/TransmissionPowerSteering.jpeg",
        "sizes" : [{
            "units" : "30",
            "measurement":"ml",
            "scale": 500,
        },{
            "units" : "20",
            "measurement":"L",
            "scale": 1,
        },{
            "units" : "6",
            "measurement":"L",
            "scale": 5,
        },{
            "units" : "1",
            "measurement":"L",
            "scale": 20,
        }],
        "tags" : [],
        "specsheet":"",
    },
{
    "name" : "Two Stroke",
    "description" : "HIGH PERFORMANCE SUPER 2 STROKE<br> OIL API TC<br> High performance all mineral oil formulated for:<br> . Air cooled two stroke engines where service conditions are extreme like chain saws, lawn mowers and equipment.<br> . Portable power equipment<br> . Air cooled two stroke motorcycles<br> . Oil injected engines (except marine outboard)<br> <br> MIXING RATIO<br> . 1:25<br><br> KEEP OUT OF REACH OF CHILDREN",
    "picture_url":"../../assets/images/product/TwoStroke.jpeg",
    "sizes" : [{
        "units" : "30",
        "measurement":"ml",
        "scale": 500,
    },{
        "units" : "20",
        "measurement":"L",
        "scale": 1,
    },{
        "units" : "6",
        "measurement":"L",
        "scale": 5,
    },{
        "units" : "1",
        "measurement":"L",
        "scale": 20,
    }],
    "tags" : [],
    "specsheet":"",
}

// {
//     "name" : "",
//     "description" : "",
//     "picture_url":"../../assets/images/product/",
//     "sizes" : [{
//         "units" : "30",
//         "measurement":"ml",
//         "scale": 500,
//     }],
//     "tags" : [],
//     "specsheet":"",
// },
]

