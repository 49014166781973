import {
    Component,
    OnInit,
    OnDestroy,
    ViewChild,
    ChangeDetectorRef,
    Inject
  } from "@angular/core";
import * as $ from 'jquery';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {FuseNavigationService} from '../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import { AppService } from "../app.service";
import {MatTableDataSource} from '@angular/material/table';
import { Observable } from 'rxjs';
import { MatPaginator } from "@angular/material";
import {FormControl} from '@angular/forms';
import {map, startWith} from 'rxjs/operators';


@Component({
    selector: 'app-shop',
    templateUrl: './shop.component.html',
    styleUrls: ['./shop.component.scss']
})
export class ShopComponent implements OnInit , OnDestroy{
    myControl = new FormControl();
    // options: string[] = this.shopService.getProductTags();
    filteredOptions: Observable<string[]>;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    obs: Observable<any>;
    lubricantsProducts = this.shopService.getProducts();
    dataSource = new MatTableDataSource(this.lubricantsProducts); 
    constructor( @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private shopService: AppService,
    private route: ActivatedRoute,  
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef) {
        // this.openMenu();

    };

    applyFilter(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

      applyFilter2(filterValue: string) {
        this.dataSource.filter = filterValue.trim().toLowerCase();
      }

    ngOnInit() {
        // this.filteredOptions = this.myControl.valueChanges.pipe(
        //     startWith(''),
        //     // map(value => this._filter(value))
        //   );
        this.changeDetectorRef.detectChanges();
        this.dataSource.paginator = this.paginator;
        this.obs = this.dataSource.connect();
        setTimeout(() => this.dataSource.paginator = this.paginator);
    }

    // private _filter(value: string): string[] {
    //     const filterValue = value.toLowerCase();
    
    //     return this.options.filter(option => option.toLowerCase().indexOf(filterValue) === 0);
    //   }
      
    
    
    ngOnDestroy() {
        if (this.dataSource) {
          this.dataSource.disconnect();
        }
      }

    openMenu(){
        $('body').removeClass('noScroll');
        if ($('.collapse').hasClass('collapse-active')) {
            $('.collapse').removeClass('collapse-active');
        }
        else {
            $('.collapse').addClass('collapse-active');
        }
    }

    onSelect(product) {
         this.router.navigate([product.name], { relativeTo: this.route });
      }
}