import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule, Routes } from '@angular/router';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { HttpModule } from '@angular/http';
import {
    MatButtonModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatRippleModule,
    MatExpansionModule,
    MatAutocompleteModule
} from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';
import 'hammerjs';
import { MatTableModule } from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule, FuseThemeOptionsModule } from '@fuse/components';
import {  } from '@agm/core';
import { fuseConfig } from 'app/fuse-config';
import { AgmCoreModule } from '@agm/core';
import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { AboutComponent } from './about/about.component';
import { HomeComponent } from './home/home.component';
import {ContactUsComponent} from "./contact-us/contact-us.component";
import {ShopComponent} from "./shop/shop.component";
import { SlideshowModule } from '../../public_api';
import { LoginComponent } from './login/login.component';
import {RegisterComponent} from "./register/register.component";
import {ForgotPasswordComponent} from "./auth/forgot-password/forgot-password.component";
import {ForgotPasswordModule} from "./auth/forgot-password/forgot-password.module";
import {FaqComponent}  from "./help/faq.component";
import {FaqModule}  from "./help/faq.module";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MatPaginatorModule } from '@angular/material';
import {AppService} from './app.service';
import { InfoComponent } from './shop/info/info.component';
import { AboutInfoComponent } from './about/info/info.component';
import { EventsComponent } from './events/events.component';
import { ProfileComponent } from './profile/profile.component';
import { WelcomepageComponent } from './welcomepage/welcomepage.component';
import { NotFoundComponent } from './not-found/not-found.component';

/*This is where the classes*/

const appRoutes: Routes = [
    {
        path      : '',
        component: HomeComponent
    },
    {
        path      : 'about',
        component: AboutComponent
    },
    {   
        path : 'about/:title',
        component : AboutInfoComponent
    },
    {
        path      : 'contact-us',
        component: ContactUsComponent
    },
    {
        path      : 'register',
        component: RegisterComponent
    },
    {
        path      : 'shop',
        component: ShopComponent
    },
    {
        path      : 'help',
        component: FaqComponent
    },
    // {
    //     path      : 'login',
    //     component : LoginComponent
    // },
    // {
    //     path      : 'register',
    //     component : RegisterComponent
    // },
    // {
    //     path      : 'forgot-password',
    //     component : ForgotPasswordComponent
    // },
    {   
        path : 'shop/:name',
        component : InfoComponent
    },
    {
        path:"news",
        component: EventsComponent
    },
    // {
    //     path : "profile",
    //     component: ProfileComponent
    // },
    // {
    //     path      : 'welcome',
    //     component: WelcomepageComponent
    // },
    {
        path: '**',
        component: NotFoundComponent,
    },
];

@NgModule({
    declarations: [
        AppComponent,
        AboutComponent,
        HomeComponent,
        ContactUsComponent,
        ShopComponent,
        LoginComponent,
        RegisterComponent,
        InfoComponent,
        AboutInfoComponent,
        EventsComponent,
        ProfileComponent,
        WelcomepageComponent,
        NotFoundComponent,

    ],
    imports     : [
        HttpModule,
        SlideshowModule,
        BrowserAnimationsModule,
        HttpClientModule,
        RouterModule.forRoot(appRoutes),
        //RouterModule.forRoot(appRoutes, {onSameUrlNavigation: '/app'}),
        BrowserModule,
        BrowserAnimationsModule,
        TranslateModule.forRoot(),
        NgbModule,
        // Material moment date module
        MatMomentDateModule,

        // Material
        MatCardModule,
        MatButtonModule,
        MatIconModule,
        MatButtonModule,
        MatFormFieldModule,
        MatInputModule,
        MatRippleModule,
        MatTableModule,
        MatGridListModule,
        MatExpansionModule,
        MatPaginatorModule,
        MatAutocompleteModule,
        
        // Maps
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCbtJ602ZKRy-vulvuuFn6xxP3dj_ih5PE'
          }),
        // Fuse modules
        FuseModule.forRoot(fuseConfig),
        FuseProgressBarModule,
        FuseSharedModule,
        FuseSidebarModule,
        FuseThemeOptionsModule,

        // App modules
        LayoutModule,
        SampleModule,
        ForgotPasswordModule,
        FaqModule
    ],
    
    bootstrap   : [
        AppComponent
    ],
    providers:[
        AppService
    ],
    exports: [RouterModule]

})
export class AppModule
{
}
