import { Component, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as $ from 'jquery';


import { FuseConfigService } from '@fuse/services/config.service';
import { fuseAnimations } from '@fuse/animations/index';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/internal/operators';
import { Router } from '@angular/router';
import {AppService} from 'app/app.service';
import { User } from 'app/model/user';

@Component({
    selector: 'register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss'],
    animations: fuseAnimations
})
export class RegisterComponent implements OnInit, OnDestroy {
    registerForm: FormGroup;
    registerFormErrors: any;
    Myuser:User;
    // Private
    private _unsubscribeAll: Subject<any>;
    //Public
    public show:boolean = false;
    public buttonName:any = 'Regitser a company';
    public defaultImg:boolean = true;
    public image:any;
    

    constructor(
        private _fuseConfigService: FuseConfigService,
        private _formBuilder: FormBuilder,
        private router: Router,
        private novasService : AppService,
    ) {
        this.openMenu();
        // Configure the layout
        this._fuseConfigService.config = {
            layout: {
                navbar: {
                    hidden: true
                },
                toolbar: {
                    hidden: true
                },
                footer: {
                    hidden: true
                }
            }
        };

        // Set the defaults
        this.registerFormErrors = {
            name: {},
            email: {},
            mobile: {},
            password: {},
            passwordConfirm: {}
        };

        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {

        this.registerForm = this._formBuilder.group({
            profile_image: [''],
            username: ['', Validators.required],
            firstName: ['', Validators.required],
            lastName: ['', Validators.required],
            email: ['', [Validators.required, Validators.email]],
            mobile: ['', [Validators.required, Validators.pattern(/^\+?\d{10}$/)]],
            password: ['', [Validators.required, Validators.minLength(6)]],
            passwordConfirm: ['', [Validators.required, confirmPassword]]
        });

        this.registerForm.valueChanges
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe(() => {
                this.onRegisterFormValuesChanged();
            });
    }

    // Image Preview
    showPreview(event) {
        const file = (event.target as HTMLInputElement).files[0];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        if (file.name == '') {
            this.defaultImg = true;
        }else{
            this.defaultImg = false;
            reader.onload = (e) => this.image = reader.result;
            console.log("Checking image " + reader.result)
        }
        reader.onerror = function (error) {
            console.log('Error: ', error);
        };
        
  }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
    }

    openMenu() {
        $('body').removeClass('noScroll');
        if ($('.collapse').hasClass('collapse-active')) {
            $('.collapse').removeClass('collapse-active');
        }
        else {
            $('.collapse').addClass('collapse-active');
        }
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On form values changed
     */
    onRegisterFormValuesChanged(): void {
        for (const field in this.registerFormErrors) {
            if (!this.registerFormErrors.hasOwnProperty(field)) {
                continue;
            }

            // Clear previous errors
            this.registerFormErrors[field] = {};

            // Get the control
            const control = this.registerForm.get(field);

            if (control && control.dirty && !control.valid) {
                this.registerFormErrors[field] = control.errors;
            }
        }
    }

    toggle() {
        this.show = !this.show;

    if(this.show)  
        this.buttonName = "Dont regitser a company";
      else
        this.buttonName = "Regitser a company";
      }

    onSubmit() {
        //checks if not null
        if (this.registerForm == null) {
            alert("no");
        } else {
            var name = this.registerForm.controls['username'].value;
            var email = this.registerForm.controls['email'].value;
            var mobile = this.registerForm.controls['mobile'].value;
            var password = this.registerForm.controls['password'].value;
            console.log("Saving image" + this.image)
            //console.log("Username: "+ name);
            //console.log(this.registerForm.value);
            //send the data to api to store
            this.Myuser = new User();
            this.Myuser.overloadedconstructor(name,email,mobile,password);
            //console.log("Console: "+this.Myuser.name);
            // console.log(this.novasService.register(this.Myuser));
            let formObj = this.registerForm.getRawValue(); // {name: '', description: ''}

            let serializedForm = JSON.stringify(formObj);   
            console.log("JSON:" + serializedForm)
            //check if no error was given else redirect to profile page
           // if (this.registerForm == null) {

          //  } else {
         //       this.router.navigate(['/profile']);
           // }
            // if the details exist then we will
            this.novasService.postData("profile", serializedForm).subscribe(data => {
                console.log("This is the side of component: "+data);
              })


        }
    }


}

/**
 * Confirm password
 *
 * @param {AbstractControl} control
 * @returns {{passwordsNotMatch: boolean}}
 */
function confirmPassword(control: AbstractControl): any {
    if (!control.parent || !control) {
        return;
    }

    const password = control.parent.get('password');
    const passwordConfirm = control.parent.get('passwordConfirm');

    if (!password || !passwordConfirm) {
        return;
    }

    if (passwordConfirm.value === '') {
        return;
    }

    if (password.value !== passwordConfirm.value) {
        return {
            passwordsNotMatch: true
        };
    }
}
