import { Component , Inject} from '@angular/core';
import * as $ from 'jquery';

import { AppService } from "../app.service";
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {FuseNavigationService} from '../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';

@Component({
  selector: 'app-help',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {

    faqsFiltered = [
        {"question":"Can we purchase products at your factory?", "answer":"<u>NOVAS LUBRICANTS</u>:<br> We exclusively supply retail stores and wholesalers<br><hr style='height:2px;border-top:1px solid #8aed3e;border-color:#8aed3e'/><u>NOVAS INDUSTRIAL</u>:<br>We can supply oil in bulk tankers, flowbins(IBC), drums and 20L containers"},
        {"question":"Do you do deliveries?", "answer":"<u>NOVAS LUBRICANTS</u>:<br> We do and strive to deliver within 48 hours of confirmation of payment<br><hr style='height:2px;border-top:1px solid #8aed3e;border-color:#8aed3e'/><u>NOVAS INDUSTRIAL</u>:<br>We do bulk deliveries."}
        // {"question":"question 1", "answer":"yes"}
    ];

    ngOnInit() {
    //     this.novasService.getData("faq").subscribe(data => {
    //     this.faqsFiltered = data["data"], 
    //     console.log(data["data"]);
    //   })
}

    constructor( @Inject(DOCUMENT) private document: any,
    private _fuseConfigService: FuseConfigService,
    private _fuseNavigationService: FuseNavigationService,
    private _fuseSidebarService: FuseSidebarService,
    private _fuseSplashScreenService: FuseSplashScreenService,
    private _fuseTranslationLoaderService: FuseTranslationLoaderService,
    private _translateService: TranslateService,
    private _platform: Platform,
    private novasService : AppService) {
        
    }



}
