import { Component, OnInit , Inject } from '@angular/core';
import * as $ from 'jquery';
import { AppService } from 'app/app.service';
import {DOCUMENT} from '@angular/common';
import {FuseConfigService} from '../../@fuse/services/config.service';
import {FuseNavigationService} from '../../@fuse/components/navigation/navigation.service';
import {FuseSidebarService} from '../../@fuse/components/sidebar/sidebar.service';
import {FuseSplashScreenService} from '../../@fuse/services/splash-screen.service';
import {FuseTranslationLoaderService} from '../../@fuse/services/translation-loader.service';
import {TranslateService} from '@ngx-translate/core';
import {Platform} from '@angular/cdk/platform';
import { ActivatedRoute, Router, ParamMap } from '@angular/router';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  novasAbout = this.novasService.getAbouts();
  constructor( @Inject(DOCUMENT) private document: any,
  private _fuseConfigService: FuseConfigService,
  private _fuseNavigationService: FuseNavigationService,
  private _fuseSidebarService: FuseSidebarService,
  private _fuseSplashScreenService: FuseSplashScreenService,
  private _fuseTranslationLoaderService: FuseTranslationLoaderService,
  private _translateService: TranslateService,
  private _platform: Platform,
  private novasService : AppService,
  private router: Router,
  private route: ActivatedRoute, ) {
  };

  ngOnInit() {       
  }

onSelect(about) {
  this.router.navigate([about.title], { relativeTo: this.route });
}


}
